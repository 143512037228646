<script setup lang="ts">
import type {DirectoryData} from '~/types/DirectoryData'
import type {FavoriteData} from '~/types/FavoriteData'
import {
  DELETE_DIALOG_DISPLAY_REQUESTED,
  MEDIA_CARD_DIRECTORIES_OPENED,
  MEDIA_PACK_SHIFT_REQUESTED,
} from '~/types/bus-event-names'
import type {MediaCardData} from '~/types/media/MediaCardData'
import {useUserStore} from "~/stores/user"
import {localeDirection} from '~/composables/useLocaleDirection'
import type {Location} from '@intlify/vue-router-bridge'

const props = defineProps<{
  cardData: MediaCardData,
  context: 'generic'|'directory'|'my-captures'|'showcase',

  // The directory the card is displayed in
  directoryData?: DirectoryData,

  // The favorite the media belongs to
  favoriteData?: FavoriteData,

  thumbnailEagerLoading?: boolean,
  preventTabbableLink?: boolean,
}>()

const bgColors = [
    'bg-magenta',
    'bg-pink',
    'bg-bubble-gum',
    'bg-navy',
    'bg-turquoise',
    'bg-green',
    'bg-red',
    'bg-orange',
    'bg-gold',
    'bg-purple',
    'bg-prune',
]

const directoriesOpen = ref<boolean>(false)
const el = ref<HTMLDivElement | null>(null)
const thumbnailWrapper = ref<HTMLDivElement | null>(null)
const emitter = useNuxtApp().$mitt
const { $imagesLoaded } = useNuxtApp()

const isCapture = computed(() => {
  return props.cardData.mediaType === 'capture'
})

const location = computed<Location>(() => {
  const location: Location = {
    name: isCapture.value ? 'capture-code' : 'media-id-slug',
  }

  if (isCapture.value) {
    location.params = {code: props.cardData.code ?? 'missing-code'}
  } else {
    location.params = {id: String(props.cardData.id), slug: props.cardData.slug ?? 'missing-slug'}
  }

  if (props.directoryData && !useUserStore().ownsDirectoryWithCode(props.directoryData.code)) {
    location.query = {dir: props.directoryData.code}
  }

  return location
})

function requestShift() {
  emitter.emit(MEDIA_PACK_SHIFT_REQUESTED)
}

function toggleDirectories() {
  useIfTeacher(() => {
    directoriesOpen.value = !directoriesOpen.value
    if (directoriesOpen.value) {
      emitter.emit(MEDIA_CARD_DIRECTORIES_OPENED, {cardData: props.cardData})
    }
    requestShift()
  })
}

useBusEvent(MEDIA_CARD_DIRECTORIES_OPENED, (event: {cardData: MediaCardData}) => {
  if (event.cardData.id !== props.cardData.id) {
    directoriesOpen.value = false
  }
})

function onDeleteClick() {
  let deleteContext
  let event

  if (props.context === "my-captures") {
    deleteContext = 'captures'
    event = {deleteContext, directory: props.directoryData, capture: props.cardData}
  } else {
    deleteContext = 'favorite'
    event = {deleteContext, directory: props.directoryData, favorite: props.favoriteData}
  }

  emitter.emit(DELETE_DIALOG_DISPLAY_REQUESTED, event)
}

const footerButtonClass = 'material-icons-outlined text-2xl cursor-pointer transition'

const publishedAt = computed(() => {
  return props.cardData.publishedAt?.date ? new Date(Date.parse(props.cardData.publishedAt.date)) : null
})

const isNew = computed(() => {
  if (!publishedAt.value) {
    return false
  }

  let monthsAgo = new Date()
  monthsAgo.setMonth(monthsAgo.getMonth() - 3)

  return publishedAt.value.getTime() > monthsAgo.getTime()
})

const thumbnailLoaded = ref<boolean>(false)

onMounted(() => {
  const _thumbnailLoaded = new $imagesLoaded(thumbnailWrapper.value)
  _thumbnailLoaded.on('always', () => {
    thumbnailLoaded.value = true
  })
})

const colorBlockClasses = computed(() => {
  return props.cardData.thumbnailBackground ? null : bgColors[props.cardData.id % bgColors.length]
})

const colorBlockStyles = computed(() => {
  return {
    backgroundColor: props.cardData.thumbnailBackground,
  }
})

const thumbnailOpacity = computed(() => {
  return thumbnailLoaded.value ? 'opacity-100' : 'opacity-0'
})

const redPixel = ref('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN8K63/HwAFbAI4DLWlhgAAAABJRU5ErkJggg==')
</script>

<template>
  <div class="u-pack-me p-half-bento" ref="el">
    <div class="relative group">
      <NuxtLink :to="localePath(location)" class="rounded-bento block transition-opacity group-active:opacity-80" :no-prefetch="true" :tabindex="preventTabbableLink ? -1 : null">
        <div class="relative" ref="thumbnailWrapper">
          <div class="absolute rounded-t-bento w-full h-full transition-opacity duration-1000" :class="colorBlockClasses" :style="colorBlockStyles"></div>
            <img :alt="cardData.title"
                 :loading="thumbnailEagerLoading ? 'eager' : 'lazy'"
                 :src="cardData.thumbnailSizes?.node_pack || redPixel"
                 class="relative w-full text-white transition-opacity duration-1000 rounded-t-bento block w-full aspect-square"
                 :class="thumbnailOpacity"
                 height="300"
                 width="300">
        </div>
        <div class="bg-white text-domain-dark rounded-b-bento relative transition-spacing
        group-hover:-mt-[40px] group-hover:pb-[40px] group-focus-within:-mt-[40px] group-focus-within:pb-[40px]">
          <div class="p-card">
            <div class="flex gap-2 text-[.75rem] leading-[.875em] mb-card">
              <span :class="{'basis-1/2': isNew}">
                {{ $t(`media.type.${cardData.mediaType}`) }}
              </span>
              <span class="basis-1/2 text-right font-bold" v-if="isNew">{{ $t('media.new') }}</span>
            </div>
            <h3 class="text-card-title" :dir="localeDirection">
              {{ cardData.title }}
            </h3>
          </div>
        </div>
      </NuxtLink>

      <div v-if="context !== 'showcase'" class="absolute bottom-card right-card flex gap-2 opacity-0 transition group-hover:opacity-100 group-focus-within:opacity-100">
        <a v-if="context !== 'directory'" :class="footerButtonClass" class="hover:text-domain" @click.prevent="toggleDirectories">folder_special</a>
        <a v-if="context === 'my-captures' || (context === 'directory' && !directoryData?.readOnly)" :class="footerButtonClass" class="hover:text-red" @click="onDeleteClick">delete</a>
      </div>
    </div>
    <MoleculesMediaCardDirectories v-if="directoriesOpen" class="mt-bento" @resize="requestShift" :media-data="cardData" context="card" />
  </div>
</template>